import React from 'react'
import Footers from '../Component/Footer/Footer'

function Footer() {
  return (
    <div>
      <Footers/>
    </div>
  )
}

export default Footer
