import React from 'react'
import Login from './Login'
import Navbar from '../Header/Navbar2'
import Footer from '../Footer/Footer'
function LoginMain() {
  return (
    <div>
      <Navbar />
      <Login/>
      <Footer/>
    </div>
  )
}

export default LoginMain
