import Navbar from '../Component/Header/Navbar'
import BG from '../Component/Header/Bg'
import React from 'react';


function Header() {
  return (
    <div>
     <Navbar/>
      <BG/>
    </div>
  )
}

export default Header
