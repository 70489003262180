import React from 'react'
import Post from './Post'
import Navbar from '../Header/Navbar1'
import Footer from '../../Main/Footer'

function PostMainn() {
  return (
    <div>
      <Navbar/>
      <Post/>
      <Footer/>
    </div>
  )
}

export default PostMainn
