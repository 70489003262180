import React from 'react'
import Navbar from '../Header//Navbar3.'
import Footer from '../../Main/Footer'
import Detail from './Detail'

function DetailMain() {
  return (
    <div>
     <Navbar/>
     <Detail/>
     <Footer/>
    </div>
  )
}

export default DetailMain
